<template>
  <div>
    <van-divider content-position="left" :style="{ color: 'red', borderColor: '#1989fa', padding: '0 16px' }">收益</van-divider>
    <van-cell-group inset style="margin-bottom: 10px;">
      <van-field label-align="left" label="日期" placeholder="选择日期" :value="args.date" @click="dateShow = true" />
      <van-field
        v-model="args.name"
        is-link
        readonly
        name="picker"
        label="主播"
        placeholder="选择主播"
        @click="anchorShow = true"
      />
      <van-button type="default" plain hairline block size="small" style="margin: 10px 0;" @click="getData">查 询</van-button>
    </van-cell-group>
    <van-pull-refresh v-model="loading" @refresh="onRefresh" success-text="数据已更新">
        <div style="padding: 10px; text-align: left" v-show="dateIncomeShow">{{incomeTitle}}: {{dateIncome}}</div>
        <div style="margin-bottom: 60px;">
            <table style="width: 98%; border: rgba(25, 137, 250, 0.2) 1px dashed; border-radius: 5px; margin: auto; font-size: 14px;">
                <tr>
                    <td class="topNoBorder">时间</td>
                    <td class="topNoBorder">用户</td>
                    <td class="topNoBorder">场景</td>
                    <td class="rightNoBorder topNoBorder">米粒</td>
                </tr>
                <tr v-for="item in income">
                    <td align="left" :style="item.act === 1 ? '' : 'color: red'">{{ item.date }} {{ item.at }}</td>
                    <td>
                        <div v-if="item.act === 1">{{ item.anchor_name }}</div>
                        <div v-else style="color: red"> / </div>
                    </td>
                    <td>
                        <div v-if="item.act === 1">{{ item.scene_cn }}</div>
                        <div v-else style="color: red">提现</div>
                    </td>
                    <td>
                        <div :style="item.act === 1 ? 'color: green' : 'color: red'">
                            <span v-if="item.act === 2">-</span>
                            <span v-else>+</span>
                            {{ item.star }}
                        </div>
                    </td>
                </tr>
            </table>
            <van-pagination
              v-if="tot > 16"
              v-model="args.p"
              :total-items="tot"
              :items-per-page="args.s"
              force-ellipses
              style="margin-top: 20px; text-align: center;"
              @change="getData"
              mode="simple"
            />
        </div>
    </van-pull-refresh>
    <van-calendar v-model:show="dateShow" :show-confirm="false" :show-subtitle="false" :show-title="false" @confirm="dateChoose" :max-date="maxDate" :min-date="minDate" :allow-same-day="true" />

    <van-popup v-model:show="anchorShow" position="bottom">
      <van-picker
        :columns="anchor"
        @confirm="anchorChoose"
        @cancel="anchorShow = false"
        show-toolbar
      />
    </van-popup>
  </div>
</template>

<script>
	import { Toast } from 'vant';
	import { Income, AnchorChoose } from '../api/default';

  export default {
    name: "Data",
    data() {
      return {
        loading: false,
        dateIncomeShow: false,
        minDate: '',
        maxDate: new Date(),
        dateShow: false,
        anchorShow: false,
        products: [],
        args: {
          id: '0', // 主播id
          name: '全部', // 主播名称
          date: '', // 日期
          p: 1, // 页码
          s: 16, // 每页条数
        },
        anchor: [],
        income: [],
        tot: 0,
        incomeTitle: '',
        dateIncome: ''
      }
    },
    created() {
      let dateString = "2024-01-01";
      this.minDate = new Date(dateString);
      // this.args.date = this.formatDate(new Date());
      this.getAnchor();
      this.getData();
    },
    methods:{
      onRefresh () {
        this.getData();
        setTimeout(() => {
          this.loading = false;
        }, 1000)
      },
      getData() {
        Income(this.args).then(res => {
          this.income = res.list;
          this.tot = res.tot;
          if (res.date_income > 0) {
            this.dateIncomeShow = true;
            let userName = '';
            if (this.args.name == '全部') {
              userName = '';
            } else {
              userName = ' ' + this.args.name;
            }
            this.incomeTitle = this.args.date + userName + ' 收益: ';
            this.dateIncome = res.date_income + "米粒";
          } else {
            this.dateIncomeShow = false;
          }
        });
      },
      async getAnchor() {
        await AnchorChoose().then(res => {
          this.anchor.push({ text: "全部", value: 0});
          res.forEach(item => {
            this.anchor.push({ text: item.name, value: item.id});
          });
        });
      },
      anchorChoose(option) {
        this.args.id = option.value;
        this.args.name = option.text;
        this.anchorShow = false;
      },
      dateChoose(date) {
        this.dateShow = false;
        this.args.date = this.formatDate(date);
      },
      formatDate(date) {
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (month < 10) {
          month = '0' + month;
        }
        if (day < 10) {
          day = '0' + day;
        }
        return `${date.getFullYear()}-${month}-${day}`;
      },
    }
  }
</script>

<style scoped>
  table > tr > td {
    border-top: rgba(25, 137, 250, 0.2) 1px dashed;
    border-right: rgba(25, 137, 250, 0.2) 1px dashed;
  }
  .rightNoBorder {
    border-right: none;
  }
  .topNoBorder {
    border-top: none;
  }
  .dropdownMenu {
    color: #3823a9;
  }
</style>
