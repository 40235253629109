import request from "../utils/request";
import Qs from "qs";

export const Qrcode = (query) => {
  return request({
    url: "qrcode",
    method: "get",
    params: query,
  });
};

export const ChangePwd = (query) => {
  return request({
    url: "change-pwd",
    method: "post",
    data: Qs.stringify(query),
  });
};

export const MyAnchor = (query) => {
  return request({
    url: "my-anchor",
    method: "get",
    params: query,
  });
};

export const AnchorChoose = (query) => {
  return request({
    url: "anchor-choose",
    method: "get",
    params: query,
  });
};

export const Income = (query) => {
  return request({
    url: "income",
    method: "get",
    params: query,
  });
};
