<template>
  <div>
    <van-divider content-position="left" :style="{ color: 'red', borderColor: '#1989fa', padding: '0 16px' }">签约用户</van-divider>
    <van-pull-refresh v-model="loading" @refresh="onRefresh" success-text="数据已更新">
        <div style="margin-bottom: 60px;">
            <table style="width: 98%; border: rgba(25, 137, 250, 0.2) 1px dashed; border-radius: 5px; margin: auto; font-size: 14px;">
                <tr>
                    <td class="topNoBorder">id</td>
                    <td class="topNoBorder">手机号</td>
                    <td class="topNoBorder">昵称</td>
                    <td class="topNoBorder">状态</td>
                    <td class="rightNoBorder topNoBorder">注册时间</td>
                </tr>
                <tr v-for="item in anchor">
                    <td align="left">{{ item.id }}</td>
                    <td>{{ item.phone_mask }}</td>
                    <td>
                    {{ item.nickname }}
                    </td>
                    <td class="rightNoBorder">
                    <van-button type="primary" v-if="item.is_fix === 1" size="mini">已激活</van-button>
                    <van-button type="warning" v-else size="mini">未激活</van-button>
                    </td>
                    <td class="rightNoBorder">
                    {{ item.create_at }}
                    </td>
                </tr>
            </table>
            <van-pagination
                v-if="tot > 20"
                v-model="pageParams.p"
                :total-items="tot"
                :show-page-size="5"
                :items-per-page="pageParams.s"
                force-ellipses
                style="margin-top: 20px; text-align: center;"
                mode="simple"
                @change="getData"
            />
        </div>
    </van-pull-refresh>
  </div>
</template>

<script>
	import { Toast } from 'vant';
	import { MyAnchor } from '../api/default';

	export default {
		name: "Anchor",
		data() {
			return {
			     loading: false,
    			 anchor: [],
                 tot: 0,
    			 pageParams: {
    			     p: 1,
                     s: 20
                 }
            }
		},
        created() {
          this.getData();
        },
        methods:{
            getData() {
                MyAnchor(this.pageParams).then(res => {
                    this.anchor = res.list;
                    this.tot = res.tot;
                    console.log(res);
                });
            },
            onRefresh () {
              this.getData();
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            }
        }
	}
</script>

<style scoped>
  table > tr > td {
    border-top: rgba(25, 137, 250, 0.2) 1px dashed;
    border-right: rgba(25, 137, 250, 0.2) 1px dashed;
  }
  .rightNoBorder {
    border-right: none;
  }
  .topNoBorder {
    border-top: none;
  }
  .dropdownMenu {
    color: #3823a9;
  }
</style>
