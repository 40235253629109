<template>
  <div>
    <van-divider content-position="left" :style="{color: 'red', borderColor: '#1989fa', padding: '0 16px' }">我的</van-divider>
    <div >

      <van-grid :column-num="3">
        <van-grid-item key="1" icon="share-o" text="邀请用户" @click="invite" />
        <van-grid-item key="2" icon="edit" text="修改密码" @click="pwd" />
        <van-grid-item key="3" icon="revoke" text="退出登录" @click="logout" />
      </van-grid>
    </div>
    <van-dialog v-model:show="inviteBox" :title="partnerName">
      <img :src='inviteQrcode' />
      <div>
        有效期至：{{inviteQrcodeExp}}
        <p style="font-size: 12px; color: #333333">截屏保存到相册分享给用户吧</p>
      </div>
    </van-dialog>
    <van-dialog v-model:show="pwdBox" title="修改密码" show-cancel-button @confirm="changePwd">
        <van-form style="padding: 25px 0;">
            <van-cell-group inset>
                <van-field
                v-model="pwdForm.password"
                type="password"
                name="密码"
                label="密码"
                placeholder="密码" />
                <van-field
                v-model="pwdForm.password2"
                type="password"
                name="确认密码"
                label="确认密码"
                placeholder="确认密码" />
            </van-cell-group>
        </van-form>
    </van-dialog>
  </div>
</template>

<script>
    import { Qrcode, ChangePwd } from '../api/default'
	import { Toast } from 'vant';

	export default {
  		name: "Mine",
  		data() {
  		return {
          partnerName: '',
          inviteBox: false,
          inviteQrcode: '',
          inviteQrcodeExp: '',
          pwdBox: false,
          pwdForm: {
            password: '',
            password2: ''
          }
        }
  	  },
      mounted() {
        this.partnerName = '扫码加入 ' + localStorage.getItem('xd_partner_user_name');
      },
      methods:{
        invite() {
          this.inviteBox = true;
          Qrcode().then(res => {
            this.inviteQrcode = 'data:image/png;base64,' + res.qrcode;
            this.inviteQrcodeExp = res.exp;
          })
        },
        pwd() {
          this.pwdBox = true;
        },
        logout() {
          Toast.success('退出成功');
          localStorage.clear();
          this.$router.push('/login');
        },
        changePwd() {
          if (!this.pwdForm.password) {
            Toast.fail('密码不能为空');
            return;
          }
          if (this.pwdForm.password !== this.pwdForm.password2) {
            Toast.fail('两次密码不一致');
            return;
          }
          ChangePwd(this.pwdForm).then(res => {
            Toast.success('修改成功,请重新登录');
            this.pwdBox = false;
            localStorage.clear();
            this.$router.push('/login');
          })
        }
      }
	}
</script>

<style scoped>
  table > tr > td {
    border-top: rgba(25, 137, 250, 0.2) 1px dashed;
    border-right: rgba(25, 137, 250, 0.2) 1px dashed;
  }
  .rightNoBorder {
    border-right: none;
  }
  .topNoBorder {
    border-top: none;
  }
  .dropdownMenu {
    color: #3823a9;
  }
</style>
